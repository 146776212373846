
:root {
  --rt-color-info: #bfaed8 !important;
}

.generate-recording {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1000;
}

.gradebook-holder {
  background: white;
  margin-top: 1em;
}

.canvas {
  max-height: 25px;
}

.import-selected {
  bottom: 25px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.import-selected > button {
  font-size: 28px;
}

.import-container {
  padding: 10px;
  background-color: white;
}

.import-item {
  cursor: pointer;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid lightgray;
  margin-bottom: 1em;
  font-size: 20px;
}

.assignment-import {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid lightgray;
  padding: 10px;
  font-size: 20px;
  max-width: 500px;
  cursor: pointer;
}

.assignment-import-holder {
  margin-top: 10px;
}

.import-item:hover {
  background-color: lightgray;
}

.gradebook {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

.gradebook td, .gradebook th {
  border: 1px solid #ddd;
  padding: 8px;
}

.gradebook tr:nth-child(even){background-color: #f2f2f2;}

/*.gradebook tr:hover {background-color: #ddd;}*/

.gradebook th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #bfaed8;
  color: white;
}

audio {
  height: 35px;
}

.login {
  display: flex;
  justify-content: center;
}

.question-settings {
  display: flex;
  gap: 0.25em;
  margin-left: 5em;
}

.recording-settings-bar {
  display: flex;
  margin-top: 0.25em;
}

.answer-key {
  margin-top: 0.35em;
}

.overdiv {
  margin-bottom: 0.2em;
}

.hubber {
  background: pink;
}

.abbreviation {
  background: white;
  border-radius: 5px;
  font-size: 14px;
  padding: 2px;
  font-weight: bold;
}

/*.music-editor {
  overflow-x: auto;
  border: 1px solid lightgray;
  padding: 5px;
}*/

.nav-active {
  color: black;
}

.nav-inactive {
  color: #0374B5;
}

.inner-nav {
  padding: 10px;
}

.inner-nav > div > div {
  padding: 5px;
  font-weight: bold;
  cursor: pointer;
}

.inner-nav > div > div:hover {
  text-decoration: underline;
}

.inner-nav-active {
  border-left: 2px solid black;
}

.inner-nav-inactive {
  border-left: 2px solid white;
}

.courses-holder {
  max-height: 50vh;
  overflow: auto;
  border-radius: 5px;
  border: 1px solid lightgray;
  padding: 15px;
}

.course-link-item {
  height: 25px;
  background: white;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 1px solid lightgray;
  padding: 8px;
  display: flex;
  align-items: center;
  width: 400px;
  justify-content: space-between;
}

.course-link-item:last-child {
  margin-bottom: 0px;
}

.course-topbar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: lightgray;
  width: calc(100vw - 84px);
  height: 45px;
  -webkit-box-shadow: 0 4px 6px -6px #222;
  -moz-box-shadow: 0 4px 6px -6px #222;
  box-shadow: 0 4px 6px -6px #222;
}

.noizy-navbar {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 84px;
  align-items: center;
  justify-content: space-between;
  -webkit-box-shadow: 0 4px 6px -6px #222;
  -moz-box-shadow: 0 4px 6px -6px #222;
  box-shadow: 0 4px 6px -6px #222;
  background: #394B58;
}

.noizy-navbar > div {
  width: 100%;
}

.assignment-creator {
  display: flex;
}

.question-menu {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border: 1px solid black;
  background: white;
  padding: 10px;
  position: absolute;
  z-index: 1000;
}

.question-nav-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.question-selected {
  background: lightgray;
}

.question-menu-item {
  cursor: pointer;
  padding: 5px; 
}

.question-menu-item:hover {
  background: #bfaed8;
}

.qlist-active {
  margin-bottom: 5px;
  cursor: pointer;
  border-radius: 5px;
  background: #bfaed8;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.qlist {
  margin-bottom: 5px;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.question-nav {
  margin-top: 10px;
}

.left-side {
  border-right: 1px solid gray;
  height: calc(100vh - 45px);
  width: 166px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.question-creator {
  border: 1px solid lightgray;
  height: calc(100% - 45px);
  display: flex;
}

.question-manager {
  display: flex;
  margin-top: 1em;
}

.overtext {
  font-weight: bold;
  font-size: 14px;
}

.course-view {
  padding: 15px;
}

.medicon {
  height: 24px;
  width: 24px;
  cursor: pointer;
}

.bigicon {
  height: 28px;
  width: 28px;
  cursor: pointer;
}

.smallishicon {
  height: 17px;
  width: 17px;
}

.smallicon {
  height: 14px;
  width: 14px;
}

.menu-icon {
  height: 26px;
  width: 26px;
}

.icon {
  height: 20px;
  width: 20px;
  cursor: pointer;
}

.smallicon {
  height: 12px;
  width: 12px;
  cursor: pointer;
}

.icon:hover {
  color: #bfaed8;
}

.course-code:hover {
  background: #bfaed8;
}

.flex-week {
  gap: 15px;
}

.flex-week > div {
  flex: 1;
}

.dropzone {
  border: 1px dotted black;
  height: 57.5px;
}

.course-list {
  margin-left: 1em;
}

.course-list-item {
  padding: 5px;
  background: #B19DD0;
  border-radius: 5px;
  margin-bottom: 3px;
}

.break-class {
  background: #E60000;
  color: white;
}

.item-x {
  border: 1px solid white;
  background: white;
}

.item-x:hover {
  border: 1px solid black;
  background: lightgray;
}

.assignment-list {
  position: absolute;
  bottom: 10px;
}

.course-component-list {
  padding: 5px;
}

.holiday {
  padding: 5px;
  background: #E60000;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

.holiday-holder {
  padding: 5px;
  position: absolute;
  bottom: 0;
}

.class-day {
  background: lightgray;
  padding: 10px;
  border-radius: 5px;
  border: 2px solid lightgray;
  height: 75px;
  position: relative;
}

.week-break {
  height: 75px;
  border: 2px solid #FFABAB;
  background: #FFABAB;
  border-radius: 5px;
  padding: 10px;
  display: flex;
  justify-content: center;
  font-size: 48px;
  font-weight: bold;
  line-height: 75px;
}

.dragover {
  border: 2px solid #b19dd0;
}

.legend-week {
  padding: 10px;
  margin-bottom: 0.5em;
}

.week-item {
  padding: 10px;
  border: 1px solid lightgray;
  margin-bottom: 0.5em;
}

/*.assignment:not(:nth-last-child(2)) {
  margin-bottom: 1em;
}*/

.assignment:not(:nth-last-child(1)) {
  border-bottom: 1px solid lightgray;
}

.assignment:hover {
  background: #E6DFF0;
}

.course-planner {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background: white;
  padding: 10px;
}

.onboard-holder {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background: white;
  padding: 10px;
  width: 300px;
}

.onboard-holder > div > input {
  padding: 5px;
  width: calc(100% - 20px);
}

.assignment {
  /*border: 2px solid lightgray;*/
  cursor: pointer;
  background: white;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  /*border-radius: 5px;*/
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 800px;
}

.course:not(:last-child) {
  margin-bottom: 1em;
}

.course:hover {
  border: 2px solid #bfaed8;
}

.course {
  border: 2px solid lightgray;
  cursor: pointer;
  background: white;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  width: 800px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.active {
  /*background: #b19dd0;*/
  background: #bfaed8;
}

#root {
  background-color: white;
  min-height: 100vh;
}

.logo {
  height: 50px;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.submission-bar {
  margin: 1em;
  margin-top: 0;
  background: white;
  border-radius: 5px;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.submission-viewer {
  margin: 1em;
}

.submission-viewer:not(:nth-last-child(1)) {
  margin-bottom: 2em;
}

.info {
  cursor: pointer;
}

.info:hover {
  color: blue;
}

.mt1 {
  margin-top: 1em;
}

.mb25 {
  margin-bottom: 0.25em;
}

.flexer {
  display: flex;
  align-items: center;
  gap: 0.5em;
}

.form-heading {
  font-weight: bold;
}

.module-choice-holder {
  max-width: 150px;
}

.module-choice {
  display: flex;
  justify-content: space-between;
}

.module-choice > input {
  cursor: pointer;
}

.instructions {
  padding: 15px;
  border-radius: 5px;
  background: white;
  margin-bottom: 1em;
}

.errorList {
	position: absolute;
	top: 200px;
	min-width: 120px;
	padding: 15px;
	border-radius: 5px;
	z-index: 10;
	background: white;
	-webkit-box-shadow: 0px 0px 3px 1px rgba(0,0,0,0.21);
	-moz-box-shadow: 0px 0px 3px 1px rgba(0,0,0,0.21);
	box-shadow: 0px 0px 3px 1px rgba(0,0,0,0.21);
  }
  
  .errorItem {
	font-family: myFirstFont;
	color: #E60000;
	/*border: 1px solid black;*/
	border-radius: 5px;
	/*margin-bottom: 15px;*/
	padding: 5px;
	/*position: relative;
	z-index: 10;*/
  }
  
  #errorBtn {
	  cursor: pointer;
  }

.check-holder {
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 0.5em;
}

.recording-holder {
  padding: 10px;
  display: flex;
  align-items: center;
  gap: 0.5em;
}

.question-doer {
  background: white;
}

.assignment-view {
  width: calc(100% - 225px);
  padding: 25px;
  padding-top: 0px;
}

.assignment-bro {
  display: flex;
}

.sidebar {
  display: flex;
  flex-direction: column;
  width: 150px;
  justify-content: space-between;
  height: calc(100vh - 81px);
  border-right: 1px solid black;
  padding: 5px;
}

.student-sidebar {
  display: flex;
  flex-direction: column;
  width: 150px;
  height: calc(100vh - 50px);
  border-right: 1px solid black;
}

.institution-chooser-background {
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0.2;
  background: black;
}

.institution-chooser {
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background: white;
  padding: 10px;
  width: 300px;
  z-index: 10000;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%,-50%);
}

.teacher-dashboard {
  display: flex;
}

.student-dashboard {
  display: flex;
}

.dashboard {
  display: flex;
}

.moduleCheck {
  display: flex;
  align-items: center;
  margin-top: 0.25em;
  gap: 0.25em;
}

/*spinny shit*/
.lds-facebook {
  display: inline-block;
  position: relative;
  width: 60px;
  height: 25px;
}
.lds-facebook div {
  display: inline-block;
  position: absolute;
  left: 8px;
  width: 10px;
  background: #bfaed8;
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}
.lds-facebook div:nth-child(1) {
  left: 8px;
  animation-delay: -0.24s;
}
.lds-facebook div:nth-child(2) {
  left: 24px;
  animation-delay: -0.12s;
}
.lds-facebook div:nth-child(3) {
  left: 40px;
  animation-delay: 0;
}
@keyframes lds-facebook {
  0% {
    top: 0px;
    height: 35px;
  }
  50%, 100% {
    top: 8px;
    height: 12px;
  }
}

.nav-select {
  border-right: 1px solid white;
}

.nav-title {
  font-size: 14px;
  display: flex;
  justify-content: center;
  margin-top: 0.3em;
  color: white;
}

.nav-menu-item {
  padding-top: 1em;
  padding-bottom: 1em;
  cursor: pointer;
  width: 100%;
}

.nav-menu-item:hover {
  background: #2D3B45;
}

.nav-menu-bottom {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  cursor: pointer;
  width: 100%;
}

/*svg:not(:root) {
    overflow: visible;
}*/

.noizy-dialog {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
}

.noizy-sdbox {
  height: 24px;
  width: 28px;
  text-align: center;
  font-size: 18px;
  font-family: myFirstFont;
  line-height: 24;
  position: absolute;
}

.noizy-anabox {
  height: 36px;
  width: 36px;
  text-align: center;
  font-size: 28px;
  font-family: myFirstFont;
  line-height: 28;
  padding: 0px;
  position: absolute;
}

.noizy-intervalbox {
  height: 36px;
  width: 36px;
  text-align: center;
  font-size: 28px;
  font-family: 'Times New Roman';
  line-height: 28;
  padding: 0px;
  position: absolute;
  border: 1px solid black !important;
}

.noizy-rnbox {
  height: 75px;
  width: 75px;
  text-align: center;
  font-size: 32px;
  font-family: myFirstFont;
  line-height: 32;
}

.noizy-pwbar {
  padding: 20px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid grey;
  justify-content: space-between;
}

.noizy-cp {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
border-radius: 5px;
  margin: auto;
  max-width: 1400px;
  padding: 25px;
  padding-top: 25px;
  padding-bottom: 5px;
  position: relative;
}

.noizy-hm {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
border-radius: 5px;
  margin: auto;
  max-width: 1400px;
  padding: 25px;
  padding-top: 25px;
  padding-bottom: 25px;
  position: relative;
}

.noizy-pw {
  /* box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px; */
border-radius: 5px;
  margin: auto;
  max-width: 1400px;
  padding: 25px;
  padding-top: 25px;
  padding-bottom: 25px;
  position: relative;
}

.noizy-rn {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
border-radius: 5px;
  margin: auto;
  margin-top: 50px;
  max-width: 1400px;
  padding: 25px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.noizy-fm {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
border-radius: 5px;
  margin: auto;
  max-width: 1400px;
  padding: 25px;
  padding-top: 25px;
  padding-bottom: 25px;
  position: relative;
}

.noizy-pwstaff {
  overflow: auto;
  position: relative;
  height: 600px;
}

/*terms and shiet*/
.noizy-collapsible {
  background-color: #777;
  color: white;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  border: none;
  text-align: left;
  outline: none;
  font-size: 15px;
}

.collapsible:after {
  content: '\002B';
  color: white;
  font-weight: bold;
  float: right;
  margin-left: 5px;
}

.content {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
  background-color: #f1f1f1;
}

/*phrase blocks*/
.noizy-blocktopline {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.noizy-block {
  background: pink;
  border-radius: 5px;
  flex: 1;
  padding: 5px;
}

.noizy-blocklabel {
  font-weight: bold;
  font-size: 1.25em;
}

.noizy-measureinput {
  width: 20px;
}

/*phrase types and shiet*/
.noizy-holdergroup {
  display: flex;
  gap: 1em;
}

.noizy-labelitem {
  cursor: pointer;
  font-size: 16px;
  padding: 5px;
}

.noizy-labelitem:hover {
  background: gray;
}

.noizy-builder {
  width: 75%;
}

.noizy-terms {
  width: 25%;
  border-radius: 5px;
  overflow: scroll;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.noizy-phrasetypeholder {
  display: flex;
  align-items: center;
  justify-content: center;
}

.noizy-level2holder {
  display: flex;
  justify-content: center;
  margin-top: 1em;
  gap: 1em;
}

.noizy-level1holder {
  display: flex;
  justify-content: center;
  margin-top: 1em;
  gap: 1em;
}

.noizy-xbutton {
  cursor: pointer;
}

.noizy-level {
  background: lightgrey;
  min-height: 100px;
  border-style: dashed;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.noizy-level-has {
  background: lightgrey;
  min-height: 100px;
  border-style: dashed;
  flex: 1;
  display: flex;
  justify-content: space-between;
  gap: 0.25em;
}

.noizy-l1 {
  background: lightgrey;
  height: 100px;
  border-style: dashed;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

@font-face {
font-family: myFirstFont;
src: url('https://app.partwriting.com/fonts/fonty.ttf');
}
@media screen and (-webkit-min-device-pixel-ratio:0) {
@font-face {
font-family: myFirstFont;
src: url('https://app.partwriting.com/fonts/fonty.ttf');
}
}

/*svg:not(:root) {
  overflow: visible;
}*/

.noizy-intchain {
  font-size: 28px;
  height: 50px;
  position: absolute;
  text-align: center;
  width: 50px;
  border: 1px solid black;
  border-radius: 5px;
}

.noizy-xbutton {
  position: absolute;
  cursor: pointer;
  top: 5px;
  right: 5px;
  background: #E60000;
  color: white;
  font-weight: bold;
  height: 25px;
  width: 25px;
  font-family: arial;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

.noizy-intervalchain-disclaimer {
  position: absolute;
  top: 5px;
  left: 5px;
}

.noizy-xbutton:hover {
  background: #F06666;
}

.noizy-regeneratebutton {
  position: absolute;
  cursor: pointer;
  top: 5px;
  right: 35px;
  background: green;
  color: white;
  font-weight: bold;
  height: 25px;
  padding-left: 5px;
  padding-right: 5px;
  font-family: arial;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
}

.noizy-regeneratebutton:hover {
  background: lightgreen;
}

#noizy-drop-area {
  border: 2px dashed #ccc;
  border-radius: 5px;
  font-family: sans-serif;
  padding: 20px;
}
#noizy-drop-area.highlight {
  border-color: purple;
}

.noizy-my-form {
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.noizy-button {
  display: inline-block;
  padding: 10px;
  background: #ccc;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid #ccc;
}
.noizy-button:hover {
  background: #ddd;
}
#noizy-fileElem {
  display: none;
}


/* Style the tab */
  .noizy-tab {
    overflow: hidden;
    border: 1px solid #ccc;
    background-color: #f1f1f1;
  }

  .noizy-modal-cover {
    position: absolute;
    top: 60px;
    left: 0px;
    height: calc(100% - 60px);
    width: 100%;
    transition: opacity 225ms;
    opacity: 0;
    border-radius: 10px;
  }

  .noizy-booton {
    filter: drop-shadow(1px 2px 2px rgb(0 0 0 / 0.4));
    -webkit-filter: drop-shadow(1px 2px 2px rgb(0 0 0 / 0.4));
    cursor: pointer;
  }

  .noizy-ac {
    display: flex;
    align-items: center;
    gap: 0.5em;
  }

  .noizy-quill-holder {
    border: 1px solid #CCC;
    height: 100px;
    width: 500px;
    border-radius: 5px;
    overflow: scroll;
  }

  .noizy-settings {
    font-family: arial;
    font-size: 14px;
    -webkit-box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    overflow: hidden;
    border: 1px solid #ccc;
    background-color: #E0E5E6;
    border-radius: 5px;
    padding: 5px;
    position: absolute;
    z-index: 1000;
    display: flex;
    align-items: center;
  }

  .noizy-student-audio {
    border: 1px solid #ccc;
    border-bottom: none;
    border-radius: 10px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .noizy-settings input[type=number] {
    max-width: 40px !important;
  }

  /* Safari */
  @-webkit-keyframes noizy-spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes noizy-spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }

  .noizy-loader {
    border: 8px solid #f3f3f3;
    border-radius: 50%;
    border-top: 8px solid #3498db;
    width: 40px;
    height: 40px;
    -webkit-animation: noizy-spin 2s linear infinite; /* Safari */
    animation: noizy-spin 2s linear infinite;
  }
  
  /* Style the buttons inside the tab */
  .noizy-tab button {
    background-color: inherit;
    float: left;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 2px 4px;
    transition: 0.3s;
    font-size: 17px;
  }
  
  /* Change background color of buttons on hover */
  .noizy-tab button:hover {
    background-color: #ddd;
  }
  
  /* Create an active/current tablink class */
  .noizy-tab button.active {
    background-color: #ccc;
  }

  .noizy-tab button.active:after {
    content: "";
  }
  
  /* Style the tab content */
  .noizy-tabcontent {
    display: none;
    padding: 6px 12px;
    border: 1px solid #ccc;
    border-top: none;
  }

  .noizy-playbutton {
    height: 50px;
    width: 50px;
    border-radius: 1000px;
  }

  .noizy-audio {
    margin-top: 1em;
  }

  .noizy-fartqueen {
    width: 1200px;
  }

  .noizy-fartking {
    border: 1px solid #ccc;
    border-radius: 10px;
  }
  
  .noizy-musicholder {
    overflow: auto;
    border: 1px solid #ccc;
    padding: 5px;
    /*max-width: 1200px;*/
    margin: auto;
  }

  .noizy-studentmusicholder {
    overflow: scroll;
    border-radius: 10px;
    padding: 15px;
    width: 1200px;
    margin: auto;
  }

  #noizy-pointer {
      cursor: pointer;
  }

  .noizy-lds-holder {
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0px;
      left: 0px;
  }

  .noizy-lds-ring {
      display: inline-block;
      position: relative;
      width: 80px;
      height: 80px;
      top: 50%;
      left: 50%;
      transform: translate(-50%,-50%);
    }
    .noizy-lds-ring div {
      box-sizing: border-box;
      display: block;
      position: absolute;
      width: 64px;
      height: 64px;
      margin: 8px;
      border: 8px solid #bfaed8;
      border-radius: 50%;
      animation: noizy-lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      border-color: #bfaed8 transparent transparent transparent;
    }
    .noizy-lds-ring div:nth-child(1) {
      animation-delay: -0.45s;
    }
    .noizy-lds-ring div:nth-child(2) {
      animation-delay: -0.3s;
    }
    .noizy-lds-ring div:nth-child(3) {
      animation-delay: -0.15s;
    }
    @keyframes noizy-lds-ring {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }

    .noizy-music-label {
      color: rgba(0, 0, 0, 0.6);
      font-family: "Roboto","Helvetica","Arial",sans-serif;
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.4375em;
      letter-spacing: 0.00938em;
      padding: 0;
      position: relative;
      display: block;
      transform-origin: top left;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 133%;
      -webkit-transform: translate(0, -1.5px) scale(0.75);
      -moz-transform: translate(0, -1.5px) scale(0.75);
      -ms-transform: translate(0, -1.5px) scale(0.75);
      transform: translate(0, -1.5px) scale(0.75);
      -webkit-transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,-webkit-transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
      transition: color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
      margin-top: 16px;
    }

    /*LOADER*/
    .loader  {
      animation: rotate 1s infinite;  
      height: 50px;
      width: 50px;
    }
    
    .loader:before,
    .loader:after {   
      border-radius: 50%;
      content: '';
      display: block;
      height: 20px;  
      width: 20px;
    }
    .loader:before {
      animation: ball1 1s infinite;  
      background-color: #bfaed8;
      box-shadow: 30px 0 0 lightgray;
      margin-bottom: 10px;
    }
    .loader:after {
      animation: ball2 1s infinite; 
      background-color: #bfaed8;
      box-shadow: 30px 0 0 lightgray;
    }

    .loader-holder {
      align-items: center;
      background-color: lightgray;
      display: flex;
      justify-content: center;
      height: 100vh;
      width: calc(100vw - 84px);
    }
    
    @keyframes rotate {
      0% { 
        -webkit-transform: rotate(0deg) scale(0.8); 
        -moz-transform: rotate(0deg) scale(0.8);
      }
      50% { 
        -webkit-transform: rotate(360deg) scale(1.2); 
        -moz-transform: rotate(360deg) scale(1.2);
      }
      100% { 
        -webkit-transform: rotate(720deg) scale(0.8); 
        -moz-transform: rotate(720deg) scale(0.8);
      }
    }
    
    @keyframes ball1 {
      0% {
        box-shadow: 30px 0 0 #bfaed8;
      }
      50% {
        box-shadow: 0 0 0 #bfaed8;
        margin-bottom: 0;
        -webkit-transform: translate(15px,15px);
        -moz-transform: translate(15px, 15px);
      }
      100% {
        box-shadow: 30px 0 0 #bfaed8;
        margin-bottom: 10px;
      }
    }
    
    @keyframes ball2 {
      0% {
        box-shadow: 30px 0 0 #bfaed8;
      }
      50% {
        box-shadow: 0 0 0 #bfaed8;
        margin-top: -20px;
        -webkit-transform: translate(15px,15px);
        -moz-transform: translate(15px, 15px);
      }
      100% {
        box-shadow: 30px 0 0 #bfaed8;
        margin-top: 0;
      }
    }
    
